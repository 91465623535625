@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

:root {
    --color-navy: #264e74;
    --color-app: #173d65
}

* {
    font-family: Karla, Inter, "Segoe UI", "Open Sans", "Helvetica Neue",
    "Helvetica", sans-serif;
    /* font-size: 0.94rem; */
    font-size: 0.98rem;
}

h6,
h5,
h4,
h3,
h2,
h1 {
    font-family: Inter, "Segoe UI", "Open Sans", "Helvetica Neue", "Helvetica",
    sans-serif !important;
}

.pointer-cursor {
    cursor: pointer !important;
}

.err {
    border: 1px solid red;
    background-color: #fdf1f0;
}

td,
th {
    color: black !important;
    padding: 0.5rem !important;
}

text-navy {
    color: var(--color-navy) !important;
}

text-appy {
    color: var(--color-app) !important;
}

hr {
    border-top: 0;
}

.btn:focus {
    border: 1px solid #6610f2;
}

.btn-simple {
    border: 1px solid #cdd4e0 !important;
    /*border-radius: 4px;*/
    padding: 0.15em 0.4em;
    cursor: pointer !important;
    font-size: 0.95em;
}

.btn-outline-simple {
    border-width: 1px;
    background-color: transparent;
    padding: 0.15em 0.4em;
    cursor: pointer !important;
    font-size: 0.95em;
}

.btn-az-darkblue {
    color: #fff;
    background-color: rgb(16, 61, 104) !important;
    border-color: rgb(16, 61, 104) !important;
}

.btn-az-darkblue:hover {
    color: #fff;
}

.font-normal {
    font-size: 15px;
}

input[type="text"],
input[type="number"],
select,
textarea {
    color: black !important;
    font-weight: 500 !important;
}

.tx-darkblue {
    color: rgb(16, 61, 104) !important;
}

label {
    font-weight: bold !important;
    /*font-size:0.9em;*/
}

.label-b-0 {
    margin-bottom: -0.5rem;
}

.input-error {
    border-color: red;
    background-color: rgba(255, 207, 210, 0.39);
    font-size: 12px;
    font-weight: bold;
}

.highlight:hover {
    background-color: #eaf1fd;
}

.main-title {
    font-size: 1.12em;
    font-weight: bold;
}

.border-gray {
    border-color: #cdd4e0;
}

.bottom-bordered {
    border: 0;
    border-bottom: 1px solid #cdd4e0;
}

.bg-required {
    background-color: #fbe5ed;
}

/* SCREEN TRANSITION */
.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-leave {
    opacity: 0;
    /*transition: opacity 300ms ease-out;*/
}

/*.fade-leave.fade-leave-active {
    opacity: 0.1;
    transition: opacity 300ms ease-out;
}*/

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

/*SIDEBAR COLORING*/
.sidebar-theme {
    /*background-image: linear-gradient(230deg, #672a63 10%, #2167a0 80%) !important;*/

    /*background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 75%) !important;*/
    /*background-image: linear-gradient(180deg, rgb(20, 36, 70) 10%, #591c6d 100%) !important;*/
    /*background-image: linear-gradient(180deg, #81397D 10%, #2573B5 100%) !important;*/

    /*background-image: linear-gradient(120deg, #383470 7%, #1D1C46 100%) !important;*/
    /*background-image: linear-gradient(230deg, #331D70 50%,#932260 80%) !important;*/
    background-image: linear-gradient(
            180deg,
            #103d68 10%,
            #103d68 80%
    ) !important;
    font-weight: bold;
    /*border-right-width: 0;
      color:white !important;*/
}

.nav-link {
    font-weight: 600 !important;
    color: var(--color-navy) !important;
}

.shadow-ex-1 {
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.75);
}

.az-navbar .nav-link.with-sub::after {
    padding-top: 5px !important;
}

.az-header {
    height: 48px;
}

.nav-sub-link {
    font-size: 0.94em !important;
    font-weight: 600 !important;
}

.nav-link-icon-title {
    padding-top: 4px;
}

.az-logo {
    color: var(--color-app) !important;

    background: #173D65;
    background: -webkit-linear-gradient(to right, #173D65 8%, #489955 17%, #428f57 26%, #80D0FF 65%);
    background: -moz-linear-gradient(to right, #173D65 8%, #489955 17%, #428f57 26%, #80D0FF 65%);
    background: linear-gradient(to right, #173D65 8%, #489955 17%, #428f57 26%, #80D0FF 65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.theme-bar {
    /* background: linear-gradient(90deg, #d3c4ff 0%, #d9e7ff 100%) !important; */
    /* background: linear-gradient(30deg, #d3c4ff 10%, #ffffff 100%) !important; */
    background: linear-gradient(90deg, #e3ffe7c5 0%, #d9e7ffbb 100%) !important;
    /* background-color: #8ec5fc;
    background-image: linear-gradient(62deg, #8ec5fc 22%, #e0c3fc 69%); */
}
.border-multicolor {
    text-align:center;
    border-bottom: 1px solid;
    /*border-image:   linear-gradient(to right, grey 25%, yellow 25%, yellow 50%,red 50%, red 75%, teal 75%) 5;*/
    border-image:linear-gradient(to right, #3582d7 8%, #489955 17%, #428f57 26%, #80D0FF 65%) 5;
}
.theme-bar-title {
     background: linear-gradient(90deg, #d3c4ff 0%, #d9e7ff 100%) !important;
    /* background: linear-gradient(30deg, #d3c4ff 10%, #ffffff 100%) !important; */
    /* background-color: #8ec5fc;
    background-image: linear-gradient(62deg, #8ec5fc 22%, #e0c3fc 69%); */
}

.page-title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 1;
    letter-spacing: -1px;

    background: #173D65;
    /* background: -webkit-linear-gradient(to right, #173D65 4%, #489955 16%, #173D65 84%, #80D0FF 98%);
    background: -moz-linear-gradient(to right, #173D65 4%, #489955 16%, #173D65 84%, #80D0FF 98%);
    background: linear-gradient(to right, #173D65 4%, #489955 16%, #173D65 84%, #80D0FF 98%); */

    background: -webkit-linear-gradient(to right, #489954 -9%, #173D65 30%, #173D65 90%, #80D0FF 67%);
    background: -moz-linear-gradient(to right, #489954 -9%, #173D65 30%, #173D65 90%, #80D0FF 67%);
    background: linear-gradient(to right, #489954 -9%, #173D65 30%, #173D65 90%, #80D0FF 67%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* 
.nav-sub-link{
    color: rgba(255, 255, 255, 0.65) !important;
} */

/*PROGRESS BAR*/

.progress .progress-bar {
    -webkit-animation: 1s linear 0s normal none infinite running progress-bar-stripes,
    animate-positive 1s;
    animation: 1s linear 0s normal none infinite running progress-bar-stripes,
    animate-positive 1s;
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
}

.btn-outline-primary:focus {
    color: #3366ff !important;
}

.btn-outline-primary:hover {
    color: white !important;
}

.btn-outline-secondary:active {
    color: #7987a1 !important;
}

.btn-outline-secondary:after,
.btn-outline-secondary:focus {
    color: #7987a1 !important;
}

.btn-outline-secondary:hover {
    color: white !important;
}

.btn-lg {
    font-size: 1em !important;
    padding: 8px 20px !important;
}

/*FROM ANGULAR PROJECT*/

.az-avatar-nodot {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.az-avatar-square {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.font-small {
    font-size: 11px !important;
}

.font-medium-small {
    font-size: 85% !important;
}

.font-medium {
    font-size: 120%;
}

.font-color-red {
    color: red !important;
}

.font-color-black {
    color: black !important;
}

.text-indigo {
    color: #6610f2 !important;
}

.text-black {
    color: black !important;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
}

.pointer-highlight {
    background-color: white;
}

.pointer-highlight:hover {
    background-color: #ebebeb !important;
}

.pointer-highlight:active {
    background-color: white !important;
}

.form-control-font {
    font-size: inherit !important;
    font-weight: 500 !important;
}

.form-control {
    color: black !important;
    font-size: inherit !important;
    font-weight: 500 !important;
}

/* .form-control:focus {
    border: 1px solid #6610f2 !important;
}
.btn:focus {
    border: 1px solid #6610f2 !important;
}

a.btn:focus{
    border: 1px solid #6610f2 !important;
} */

.form-control-free {
    display: inline-block !important;
}

.date-input {
    border: 0px;
    cursor: pointer;
}

.form-select {
    padding: 0.175rem 0.25rem 0.165rem 0.65rem !important;
}

.form-label {
    margin-bottom: 2px !important;
}

.link {
    font-weight: bold;
    color: indigo;
}

.smooth-corner {
    border-radius: 5px !important;
}

/* .modal-corner div {
    border-radius: 5px !important;
} */


.card {
    border-radius: 5px !important;
    border: 1px solid #dddddd !important;
}

.card-header {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    background-color: #f8f8f8;
    border-bottom: 1px solid #efefef !important;
    padding: 0.75rem 1.25rem 0.6rem 1.25rem !important;
}

button,
input,
select,
textarea {
    border-radius: 3px !important;
    font-size: inherit !important;
    font-weight: 500 !important;
}

.az-body {
    background-color: #f6f6f7 !important;
}

.holder-expand {
    left: 0 !important;
}

.float-bottom {
    position: absolute;
    bottom: 0;
}

.card-login {
    height: 460px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 480px) {
    .card-login {
        border: 1px solid #cdd4e0;
        width: 360px;
        padding: 30px 35px;
    }
}

@media (max-width: 991px) {
    /* Submenu on mobile shouldn't have shadow */
    .shadow-lg {
        box-shadow: none !important;
    }
}

/*.table thead th, .table thead td {*/
/*    border-top-left-radius: 10px !important;*/
/*}*/

.srushti__control {
    min-height: 32px !important;
    border-radius: 3px !important;
    border-width: 1px !important;
    font-size: inherit !important;
    font-weight: 500 !important;
}

.srushti__control:focus {
    border-width: 1px !important;
    box-shadow: none !important;
    border-color: #3366ff !important;
    font-size: inherit !important;
    font-weight: 500 !important;
}

.srushti__control--is-focused {
    border-width: 1px !important;
    box-shadow: none !important;
    border-color: #3366ff !important;
    font-size: inherit !important;
    font-weight: 500 !important;
}

.srushti__value-container {
    min-height: 20px !important;
    padding: 0 !important;
    padding-left: 4px !important;
    font-size: inherit !important;
    font-weight: 500 !important;
}

.srushti__indicator {
    padding: 0px !important;
}

.Toastify__toast {
    border-radius: 4px !important;
    min-height: 48px !important;
    padding-left: 18px !important;
    padding-right: 10px !important;
    box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.1),
    0 2px 15px 0 rgba(0, 0, 0, 0.25) !important;
}

.Toastify__close-button {
    display: none !important;
}

.standard-border-radius {
    border-radius: 7px !important;
}

p {
    margin-bottom: 0 !important;
}

/* Toggle Button */
.cm-toggle {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.cm-toggle:focus {
    outline: 2px dotted #3366ff;
}

/* To create surface of toggle button */
.cm-toggle:after {
    content: "";
    width: 1em;
    height: 1em;
    display: inline-block;
    background: rgba(196, 195, 195, 0.55);
    border-radius: 18px;
    clear: both;
}

/* Contents before checkbox to create toggle handle */
.cm-toggle:before {
    content: "";
    width: 1.3em;
    height: 1.3em;
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Shift the handle to left on check event */
.cm-toggle:checked:before {
    left: 1em;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* Background color when toggle button will be active */
.cm-toggle:checked:after {
    background: #3366ff;
}

/* Transition for smoothness */
.cm-toggle,
.cm-toggle:before,
.cm-toggle:after,
.cm-toggle:checked:before,
.cm-toggle:checked:after {
    transition: ease 0.3s;
    -webkit-transition: ease 0.3s;
    -moz-transition: ease 0.3s;
    -o-transition: ease 0.3s;
}

/* Fix for Button dropdown in stocks tables */
.table-responsive .dropdown-menu {
    position: absolute !important;
}

.table-responsive {
    overflow: visible;
}

.table-scrollable {
    overflow-x: auto;
    max-width: 577px;
    box-shadow: inset 0 0 5px rgba(150, 150, 150, 0.35);
    margin: auto;
}

.loader-container {
    display: grid;
}

.loader-content,
.loader-overlay {
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px) !important;
    transition: all 0.4s;
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    grid-area: 1 / 1;
}

.modal-backdrop {
    /*background: rgba(0, 0, 0, 0.1);*/
    background-color: #9c9da1b6 !important;
    -webkit-backdrop-filter: blur(1px) !important;
    backdrop-filter: blur(10px) saturate(1%) !important;

}

.modal-content {
    border-radius: 5px !important;
}

.modal-dialog {
    box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.44);
    -webkit-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.44);
}
